<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :headers="headers" :items="tableItems" hide-default-footer disable-pagination
          class="elevation-1">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">Organizations</h2>
              <v-btn v-if="issuperadministrator" @click="sendInvoiceBasisEmail()" color="white">
                <v-icon>mdi-email</v-icon>
                <span class="ml-2">Send Invoice Data</span>
              </v-btn>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" color="white" @click="newitem()"><v-icon medium
                    class="py-2 mr-2">mdi-plus</v-icon> New</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.isSuperAdminOrganization="{ item }">
            <v-simple-checkbox v-if="item.isSuperAdminOrganization" v-model="item.isSuperAdminOrganization"
              disabled></v-simple-checkbox>
          </template>

          <template v-slot:item.sendInvoiceData="{ item }">
            <v-simple-checkbox v-if="!item.isSuperAdminOrganization" @click="saveSendInvoiceDataUpdate(item)"
              v-model="item.sendInvoiceData"></v-simple-checkbox>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="!item.isSuperAdminOrganization" medium class="pa-2"
              @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon v-if="!item.isSuperAdminOrganization" medium class="pa-2"
              @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="800px" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }} - Organization</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid" ref="organizationform">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field required :rules="reqRules" v-model="editedItem.name" label="Name"></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12">
                      <div>
                        <v-simple-checkbox v-model="editedItem.sendInvoiceData"></v-simple-checkbox>
                      </div>
                      <p class="ma-auto ml-0" style="text-align: center; max-height: fit-content; font-size: 16px;">
                        Include
                        this
                        organization
                        when sending
                        invoice
                        data</p>
                    </v-col>
                    <v-col class="d-flex" cols="12">
                      <div>
                        <v-simple-checkbox v-model="editedItem.useForms"></v-simple-checkbox>
                      </div>
                      <p class="ma-auto ml-0" style="text-align: center; font-size: 16px;">Use forms in organization</p>
                    </v-col>
                    <v-col class="d-flex" cols="12">
                      <v-btn tile outlined @click="newImageDialog = true"><v-icon medium
                          class="py-2 mr-2">mdi-upload</v-icon>Upload logo</v-btn>
                      <p v-if="this.uploadedImage != null"
                        style="margin: auto; margin-right: 6px; margin-left: 12px; font-size: medium;">
                        {{
                          this.uploadedImage.filename
                        }}</p>
                      <v-icon v-if="this.uploadedImage != null"
                        @click="clearFileInput(); editedItem.imagePath = null">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="newImageDialog" max-width="600px" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              Select image(s)
            </v-card-title>
            <v-container>
              <form id="image-dropbox-form" enctype="multipart/form-data" novalidate>
                <div class="dropbox">
                  <input id="file-input" type="file" multiple name="photos" accept="image/*" class="input-file"
                    @change="filesChange($event)">
                  <p v-if="!isUploadingFiles" class="upload-text">Drag your file here</p>
                  <p v-if="!isUploadingFiles" class="upload-text">or click to browse.</p>
                  <p v-if="!isUploadingFiles && this.uploadedImage == null" class="upload-text file-count">
                    File selected:
                  </p>
                  <p v-if="!isUploadingFiles && this.uploadedImage != null" class="upload-text file-count">File
                    selected: {{
                      this.uploadedImage.filename
                    }}</p>

                  <!-- {{ uploadedImage
                      === null ?
                      " no file selected" : uploadedImage.filename }} -->
                  <p v-if="isUploadingFiles" class="upload-text">Uploading image...</p>
                </div>
              </form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="newImageDialog = false; clearFileInput()"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="newImageDialog = false"
                :disabled="!isImageFormValid"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon> Continue</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isImageFormValid: false,
      dialog: false,
      newImageDialog: false,
      isUploadingFiles: false,
      search: '',
      footerProps: { 'items-per-page-options': [50, 100] },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Super admin org.', value: 'isSuperAdminOrganization' },
        { text: 'Send invoice data', value: 'sendInvoiceData' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 }
      ],
      tableItems: [],
      editedItem: {},
      uploadedImage: null,
      logoImage: null,
      organizationId: -1,
      newItem: false,
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,
    }
  },
  created() {
    this.getitems()
  },
  computed: {
    ...mapGetters([
      "issuperadministrator",
      "organizationid"
    ]),
  },
  methods: {
    filesChange(e) {
      if (!e.target.files.length) {
        this.isImageFormValid = false
        return;
      }
      this.isImageFormValid = true
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const logoImageToUpload = {
          filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
        this.uploadedImage = logoImageToUpload
      }
      reader.readAsDataURL(file);
    },
    async saveImage() {
      this.isUploadingFiles = true

      if (this.uploadedImage.filedata != undefined) {
        await HTTP.post(`/organization/${this.organizationId}/save/logo`, this.uploadedImage).then((response) => {
          this.isUploadingFiles = false
          this.uploadedImage = { filename: response.data }
          this.editedItem.imagePath = response.data
          this.newImageDialog = false
          this.clearFileInput()
        }).catch((e) => {
          this.isUploadingFiles = false
          this.clearFileInput()
          this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        })
      }
    },
    clearFileInput() {
      this.uploadedImage = null;
      this.isImageFormValid = false;
    },
    saveSendInvoiceDataUpdate(item) {
      HTTP.post("/organization/save/sendinvoicedata", item).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    },
    sendInvoiceBasisEmail() {
      this.$root.$confirm
        .open("Send Invoice Data Email", "Are you sure?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post('/organization/send_invoice_data').catch(e => {
              this.$store.dispatch('showError', e.response.data)
              this.$router.push('/issues')
            })
          }
        });
    },
    getitems() {
      HTTP.get('/organization/get').then(response => {
        this.tableItems = response.data
      }).catch(e => {
        this.$store.dispatch('showError', e.response.data)
        this.$router.push('/issues')
      })
    },
    newitem() {
      this.clearFileInput()
      this.newItem = true
      this.editedItem = { id: 0, name: null, isSuperAdminOrganization: false }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      this.clearFileInput()
      var self = this
      self.editedItem = Object.assign({}, item)
      if (item.imagePath != null) {
        this.uploadedImage = { filename: item.imagePath }
      }
      self.dialog = true
      setTimeout(() => {
        if (this.$refs.organizationform) this.$refs.organizationform.resetValidation()
      }, 50)
    },
    save() {
      var self = this;
      HTTP.post('/organization/save', self.editedItem).then(async response => {
        this.organizationId = response.data.id
        if (this.uploadedImage != null) {
          await this.saveImage()
        }
        if (self.editedItem.id > 0) {

          var editedIndex = self.tableItems.findIndex(q => q.id === response.data.id);
          self.tableItems[editedIndex].name = response.data.name;
          self.tableItems[editedIndex].isSuperAdminOrganization = response.data.isSuperAdminOrganization;
          self.tableItems[editedIndex].sendInvoiceData = response.data.sendInvoiceData;
          self.tableItems[editedIndex].useForms = response.data.useForms;
          if (Number(this.organizationid) === response.data.id) {
            if (response.data.useForms) {
              this.$store.dispatch('doesOrganizationUseForms', true)
            } else {
              this.$store.dispatch('doesOrganizationUseForms', false)
            }
          }
          self.tableItems[editedIndex].imagePath = response.data.imagePath === null ? this.editedItem.imagePath : response.data.imagePath;
        } else {
          const newOrganization = response.data;
          newOrganization.imagePath = this.editedItem.imagePath
          self.tableItems.push(newOrganization)
          self.$store.dispatch('addOrganizationitem', newOrganization)
        }
        self.clearInputField()
        self.dialog = false
      }).catch(e => {
        self.clearInputField()
        self.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    deleteItem(item) {
      const self = this
      this.$root.$confirm.open('Delete item', 'All related QR data including users will be deleted!<br/><br/>Are you sure you want to continue?.', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/organization/delete', item).then((response) => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1);
            self.$store.dispatch('saveOrganizationId', -1);
            self.$store.dispatch('deleteOrganizationitem', item)

            response.data.forEach(workspace => {
              self.$store.dispatch('deleteWorkspaceitem', workspace)

              if (self.workspaceid == workspace.id) {
                self.$store.dispatch('saveWorkspaceId', -1);
              }
            });
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    clearInputField() {
      document.getElementById('image-dropbox-form').reset();
    },
    getUserFullName(item) {
      return `${item.firstname} ${item.lastname}`;
    },
  }
}
</script>
<style scoped>
.image-dialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dropbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;

  background-color: #EBF5FB;
  width: 100%;
  height: 100px;
  border-radius: 15px;
  border: 2px solid lightgrey;
  position: relative;
}

.input-file {
  width: 100%;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.upload-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
}

.file-count {
  font-weight: bold;
  font-style: italic;
}

::file-selector-button {
  display: none;
}
</style>
