<template>
  <div class="adminbackground">
    <AutoLogout></AutoLogout>
    <appbar></appbar>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutoLogout from "../../components/AutoLogout.vue";
import { RouterView } from "vue-router";

export default {
  components: { AutoLogout, RouterView },
  created() {
  },
  computed: {
    ...mapGetters(["workspaceid", "isOrganizationFormsDisabled"]),
  },
  methods: {
    redirectIfOrganizationFormsDisabled() {
      if (!this.isOrganizationFormsDisabled)
        this.$router.push('/issues')
    },
  },
};
</script>
