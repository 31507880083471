<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :headers="headers" :items="statisticsResult" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
          :footer-props="footerProps" style="white-space: pre-line;" class="elevation-1">
          <template v-slot:item.registeredCount="{ item }">
            <a @click="showRelatedQr(item)">{{ item.registeredCount }}</a>
          </template>

          <template v-slot:item.reportCount="{ item }">
            <a @click="showRelatedIssues(item)">{{ item.reportCount }}</a>
          </template>

          <template v-slot:item.errorPercentage="{ item }">
            {{ item.errorPercentage }}
          </template>

          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">Statistics</h2>
              <Tooltip :message="tooltipMessage"></Tooltip>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols="4">
                <v-menu v-model="menustart" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="datestart" label="Start date" class="ml-4" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="datestart" @input="selectdate()"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu v-model="menuend" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateend" label="End date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateend" @input="selectdate()"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="show-not-registered"><v-checkbox v-model="showNotRegistered" label="Show not registered"
                  @click="updatereport()"></v-checkbox></v-col>
              <v-col cols="1"><v-btn text @click="clearfilter()" class="clear-btn" width="30px">Clear</v-btn></v-col>
            </v-row>
          </template>
        </v-data-table>


      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/Tooltip.vue'

export default {
  name: 'models',
  components: { Tooltip, },
  data() {
    return {
      datestart: null,
      dateend: null,
      menustart: false,
      menuend: false,
      showNotRegistered: false,

      headers: [
        { text: 'Description', align: 'left', value: 'description', width: '40%' },
        { text: 'Registered on\n(QR Codes)', value: 'registeredCount' },
        { text: 'Reported on\n(Issues)', value: 'reportCount' },
        { text: 'Error per registered\n(%)', value: 'errorPercentage' },
        { text: 'Waiting', value: 'countWaiting' },
        { text: 'Ongoing', value: 'countOngoing' },
        { text: 'Closed', value: 'countClosed' },
      ],
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      statisticsResult: [],

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },
      tooltipMessage: [
        "Statistics",
        'This section provides insights into failing inventories. "Error per registered" shows the percentage of added inventories that have failed. Note that individual items are not separated, so it could be one inventory that frequently fails. Click on "Registered on" to see which QR codes have that particular inventory added. Click on "Reported on" to see which issues have that particular inventory added.',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
  },
  created() {
    this.updatereport();
    this.sortOptions = this.sort.statisticSort || this.sortOptions;
    EventBus.$on('updatestatistics', workspaceid => {
      this.updatereport(workspaceid);
    });

  },
  methods: {
    updatereport() {
      HTTP.post('/statistics/get/' + this.showNotRegistered + '/' + this.workspaceid, { datestart: this.datestart, dateend: this.dateend }).then(response => {
        this.statisticsResult = response.data
      })
    },
    selectdate() {
      this.menustart = false
      this.menuend = false
      this.updatereport()
    },
    clearfilter() {
      this.datestart = null
      this.dateend = null
      this.showNotRegistered = false
      this.updatereport()
    },
    showRelatedIssues(item) {
      this.$router.push({ name: 'Issues', params: { showClosed: true, inventoryId: `InventoryId(${item.id})` } });
    },
    showRelatedQr(item) {
      this.$router.push({ name: 'qr', params: { inventoryId: `InventoryId(${item.id})` } });
    },
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.statisticSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    }
  },
}
</script>
