import Vue from 'vue'
import VueRouter from 'vue-router'
import Issues from '@/views/Admin/Issues.vue'
import Qr from '@/views/Admin/Qr.vue'
import QrCategory from '@/views/Admin/QrCategory.vue'
import Inventory from '@/views/Admin/Inventory.vue'
import Statistics from '@/views/Admin/Statistics.vue'
import Users from '@/views/Admin/Users.vue'
import Organization from '@/views/Admin/Organization.vue'
import Workspace from '@/views/Admin/Workspace.vue'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import RecoverPassword from '@/views/RecoverPassword'
import ChangePassword from '@/views/ChangePassword'
import MobileSelect from '@/views/MobileSelect'
import MobileReport from '@/views/MobileReport'
import MobileForm from '@/views/MobileForm'
import QRImage from '@/views/QRImage'
import MobileEdit from '@/views/MobileEdit'
import MobileEditSuccess from '@/views/MobileEditSuccess'
import Form from '@/views/Admin/Form'
import Location from '@/views/Admin/Location'
import MapView from '@/views/MapView'
import MobileLogin from '../views/MobileLogin.vue'
import MobileMain from '../views/MobileMain.vue'
import MobileRequestSuccess from '../views/MobileRequestSuccess.vue'
import SystemInventoryType from '../views/Admin/SystemInventoryType.vue'
import AdminMain from '../views/Admin/AdminMain.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/issues',
  },

  {
    path: '/',
    component: AdminMain,
    children: [
      {
        path: 'issues',
        name: 'Issues',
        component: Issues,
        props: true
      },
      {
        path: 'qr',
        name: 'qr',
        component: Qr,
        props: true
      },
      {
        path: 'qrcategory',
        name: 'Qrcategory',
        component: QrCategory
      },
      {
        path: 'form',
        name: 'Form',
        component: Form
      },
      {
        path: 'location',
        name: 'Location',
        component: Location
      } ,
      {
        path: 'inventory',
        name: 'Inventory',
        component: Inventory
      },
      {
        path: 'inventorytype/system',
        name: 'SystemInventoryType',
        component: SystemInventoryType
      },
      {
        path: 'qrimage/:qrstring',
        name: 'QRImageLegacy',
        props: true,
        component: QRImage
      },
      {
        path: 'qrimage/:workspaceid/:qrworkspaceid',
        name: 'QRImage',
        props: true,
        component: QRImage
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: Statistics
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'organization',
        name: 'Organization',
        component: Organization
      },
      {
        path: 'workspace',
        name: 'Workspace',
        component: Workspace
      },
    ]
  },
  {
    path: '/m/:qrstring',
    props: true,
    component: MobileMain,
    children: [
      {
        path: "",
        props: true,
        name: "MobileSelectLegacy",
        component: MobileSelect
      },
      {
        path: "login",
        props: true,
        name: "MobileLegacyLogin",
        component: MobileLogin
      },
      {
        path: 'report/:qrdetailid',
        props: true,
        name: 'MobileLegacyReport',
        component: MobileReport
      },
      {
        path: 'form/:formtype',
        props: true,
        name: 'MobileLegacyForm',
        component: MobileForm
      },
      {
        path: 'success',
        name: 'MobileLegacyRequestSuccess',
        component: MobileRequestSuccess
      },
    ]
  },
  {
    path: '/m/:workspaceid/:qrworkspaceid',
    props: true,
    component: MobileMain,
    children: [
      {
        path: "",
        props: true,
        name: "MobileSelect",
        component: MobileSelect
      },
      {
        path: "login",
        props: true,
        name: "MobileLogin",
        component: MobileLogin
      },
      {
        path: 'report/:qrdetailid',
        props: true,
        name: 'MobileReport',
        component: MobileReport
      },
      {
        path: 'form/:formtype',
        props: true,
        name: 'MobileForm',
        component: MobileForm
      },
      {
        path: 'success',
        name: 'MobileRequestSuccess',
        component: MobileRequestSuccess
      },
    ]
  },
  {
    path: '/m/edit/success',
    name: 'MobileEditSuccess',
    props: true,
    component: MobileEditSuccess
  },
  {
    path: '/m/edit/:workspaceid/:qrworkspaceid',
    name: 'EditMobile',
    props: true,
    component: MobileEdit
  },
  {
    path: '/displaymap/:imageid/:latlng',
    name: 'MapPage',
    props: true,
    component: MapView
  },
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/recoverpassword',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  {
    path: '/changepassword/:guid',
    name: 'ChangePassword',
    props: true,
    component: ChangePassword
  },
]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' || to.name === 'ChangePassword' || to.name === 'RecoverPassword' || to.name.startsWith('Mobile') || to.name === 'MapPage') {
    next()
  }
  else if ((localStorage.isauthorized === 'undefined' || localStorage.isauthorized === undefined || localStorage.isauthorized === false)) {
    if (from.name === 'Mobile' || from.name === 'MobileLegacy'){
      next({ name: 'Login' , params: { fromRoute: from }})
    }
    else {
      next("/Login")
    }
  } else {
      next()
  }
})

export default router
