<template>
  <v-card>
    <div class="appbar row justify-end align-center">

      <div class="col-md-3 d-flex justify-start">
        <button @click="goToHome">
          <img src="./../assets/service-me-logo.png" class="biglogo appbar-logo-margin">
        </button>
      </div>

      <div class="col-md-6 d-flex justify-center">
        <div class="d-flex flex-column">
          <v-row class="mx-02">
            <v-col v-if="issuperadministrator" cols="6" class="pa-0">
              <v-select @change="setSelectedOrganization(selectedorganizationid)" :items="organizationitems"
                v-model="selectedorganizationid" item-text="name" item-value="id" label="Choose organization" solo
                class="pl-3"></v-select>
            </v-col>
            <v-col class="pa-0">
              <v-select :items="workspaceitems" v-model="selectedworkspaceid"
                v-on:change="setSelectedWorkspace(selectedworkspaceid)" item-text="name" item-value="id"
                label="Choose workspace" solo class="pl-3"></v-select>
            </v-col>
          </v-row>
          <div class="d-flex justify-center mb-2">
            <v-btn class="adminbutton" to="/issues"><v-icon class="pa-2">mdi-check-circle</v-icon> Issues</v-btn>
            <v-btn class="adminbutton" to="/qr"><v-icon class="pa-2">mdi-qrcode</v-icon> QR codes</v-btn>
            <v-btn class="adminbutton" to="/inventory"><v-icon class="pa-2">mdi-pencil</v-icon> Inventory</v-btn>
            <v-btn class="adminbutton" to="/statistics"><v-icon class="pa-2">mdi-chart-line</v-icon> Statistics</v-btn>
          </div>
        </div>
      </div>
      <div class="col-md-3 d-flex justify-end">
        <div class="d-flex flex-column">
          <v-btn class="adminbutton mb-8" to="/logout"><v-icon class="pa-2">mdi-logout</v-icon>Log out</v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="adminbutton" v-bind="attrs" v-on="on"><v-icon class="mr-2">mdi-cog</v-icon>Admin</v-btn>
            </template>
            <v-list>
              <v-list-item link v-for="(item, index) in menuitems" :key="index" router :to="item.url">
                <v-list-item-title class="menuitems"><v-icon>{{ item.icon }}</v-icon> {{ item.title
                  }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>
      </div>
    </div>

    <v-progress-linear :active="loading" :indeterminate="loading" height="6" absolute bottom
      color="blue accent-2"></v-progress-linear>

  </v-card>
</template>
<script>

import { HTTP } from '@/plugins/backendAPI';
import { mapGetters } from 'vuex';
import { EventBus } from '@/store/event-bus.js';

export default {
  data() {
    return {
      selectedorganizationid: 0,
      selectedworkspaceid: 0,
      menuitems: [],
      loading: false,
    }
  },
  created() {
    this.getEverything()
  },
  computed: {
    ...mapGetters([
      'workspaceid',
      'legacyworkspaceid',
      'workspaceitems',
      'organizationid',
      'organizationitems',
      'isadministrator',
      'issuperadministrator'
    ])
  },
  methods: {
    goToHome() {
      this.$router.push('/')
    },
    async getEverything() {
      await this.getorganizations();
      this.fillmenuitems()
      this.getworkspaces(false);
    },
    fillmenuitems() {
      if (this.issuperadministrator) {
        this.menuitems.push({ title: 'Organizations', icon: 'mdi-domain', url: '/organization' })
        this.menuitems.push({ title: 'System Inventory Types', icon: 'mdi-dresser-outline', url: '/inventorytype/system' })
      }
      if (this.isadministrator || this.issuperadministrator) {
        this.menuitems.push({ title: 'Users', icon: 'mdi-account', url: '/users' })
        this.menuitems.push({ title: 'Workspaces', icon: 'mdi-sitemap', url: '/workspace' })
      }
      this.menuitems.push({ title: 'QR categories', icon: 'mdi-devices', url: '/qrcategory' })
      this.menuitems.push({ title: 'Locations', icon: 'mdi-home-map-marker', url: '/location' })

      this.$nextTick(() => {
        const currentOrganization = this.organizationitems.find(o => o.id === Number(this.selectedorganizationid))
        if (currentOrganization.useForms) {
          this.$store.dispatch('doesOrganizationUseForms', true)
          this.menuitems.push({ title: 'Forms', icon: 'mdi-format-list-numbered', url: '/form' })
        } else {
          this.$store.dispatch('doesOrganizationUseForms', false)
        }
      })

    },
    async getorganizations() {
      if (this.issuperadministrator) {
        var self = this;
        if (self.organizationitems.length < 1) {
          await HTTP.get('/organization/get')
            .then((response) => {
              if (response.data.length === 0) {
                self.$store.dispatch('saveOrganizationId', -1)
              }
              else {
                const localStorageOrganizationIdExists = response.data.find(w => w.id == self.organizationid)
                if (!localStorageOrganizationIdExists) self.$store.dispatch('saveOrganizationId', -1)
                response.data.forEach(element => {
                  self.$store.dispatch('addOrganizationitem', element)
                });
              }
            })
        }
        if (self.organizationid === undefined) {
          self.selectedorganizationid = self.organizationitems[0].id;
        } else {
          self.selectedorganizationid = Number(self.organizationid);
        }
      }
    },
    getworkspaces(newWorkSpaceSelected) {
      var self = this;

      if (newWorkSpaceSelected) {
        self.$store.dispatch('clearWorkspaceitems')
      }

      if (self.workspaceitems.length < 1) {
        HTTP.get('/workspace/getlist/' + self.organizationid).then(response => {
          self.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyWorkspaceId)
          if (response.data.workspaces.length === 0) {
            self.$store.dispatch('saveWorkspaceId', -1)
          }
          else {
            const localStorageWorkspaceIdExists = response.data.workspaces.find(w => w.id == self.workspaceid)
            if (!localStorageWorkspaceIdExists) self.$store.dispatch('saveWorkspaceId', -1)
            response.data.workspaces.forEach(element => {
              self.$store.dispatch('addWorkspaceitem', element)
            });
          }
        })
      }

      if (self.workspaceid === undefined) {
        self.selectedworkspaceid = self.workspaceitems[0].id;
      } else {
        self.selectedworkspaceid = Number(self.workspaceid);
      }
    },
    setSelectedWorkspace(workspaceid) {
      this.$store.dispatch('saveWorkspaceId', this.selectedworkspaceid);
      var emitmethod;
      switch (this.$route.name.toLowerCase()) {
        case 'qr': emitmethod = ['updateqritems']; break;
        case 'issues': emitmethod = ['updateissues']; break;
        case 'inventory': emitmethod = ['updateinventory', 'updateinventorytype']; break;
        case 'statistics': emitmethod = ['updatestatistics']; break;
        case 'qrcategory': emitmethod = ['updateqrcategory']; break;
        case 'inventorytype': emitmethod = ['updateinventorytype']; break;
        case 'location': emitmethod = ['updatelocation']; break;
        case 'form': emitmethod = ['updateform'];
      }
      if (emitmethod !== undefined) {
        this.loading = true
        for (let x = 0; x < emitmethod.length; x++) {
          EventBus.$emit(emitmethod[x], workspaceid);
        }
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    },
    setSelectedOrganization(organizationid) {
      var self = this;
      self.$store.dispatch('saveOrganizationId', self.selectedorganizationid);
      this.menuitems = []
      this.fillmenuitems()

      HTTP.get('/organization/getWorkspaceId/' + organizationid)
        .then(response => {
          self.selectedworkspaceid = response.data;
          if (response.data === -1) {
            self.$store.dispatch('saveWorkspaceId', -1)
          }
          else {
            self.$store.dispatch('saveWorkspaceId', response.data)
          }

          self.getworkspaces(true);

          var emitmethod;
          switch (self.$route.name.toLowerCase()) {
            case 'qr': emitmethod = ['updateqritems']; break;
            case 'issues': emitmethod = ['updateissues']; break;
            case 'inventory': emitmethod = ['updateinventory', 'updateinventorytype']; break;
            case 'statistics': emitmethod = ['updatestatistics']; break;
            case 'qrcategory': emitmethod = ['updateqrcategory']; break;
            case 'inventorytype': emitmethod = ['updateinventorytype']; break;
            case 'location': emitmethod = ['updatelocation']; break;
            case 'form': emitmethod = ['updateform']; break;
            case 'workspace': emitmethod = 'updateworkspaceitems';
          }

          if (emitmethod !== undefined) {
            self.loading = true
            for (let x = 0; x < emitmethod.length; x++) {
              EventBus.$emit(emitmethod[x], self.workspaceid);
            }
            setTimeout(() => {
              self.loading = false
            }, 1000)
          }
        })
    }
  }
}
</script>
<style lang="css" scoped></style>
