<template>
  <div v-if="location !== undefined"
    style="align-items: center; display:flex; flex-direction: column; position: relative; width: 270px">
    <v-progress-linear :active="loading" indeterminate color="blue" style="margin-top: 12px;"></v-progress-linear>
    <div v-if="location.pincode.hint != '' && location.pincode.hint != null" style="text-align: center;">{{
      location.pincode.hint }}</div>
    <div v-else style="text-align: center;">Enter pincode to continue</div>
    <div style=" width: 230px;">
      <v-otp-input v-model="pinCode" ref="pininput" :error="isPinError" @keydown="checkDigit" type="password" length="4"
        inputmode="numeric" autofocus>
      </v-otp-input>
      <div class="d-flex" v-if="isPinError">
        <div style="height: 24px; width: 24px;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>alert-circle</title>
            <path style="fill: #ff5252;"
              d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
          </svg>
        </div>
        <p style="color: #ff5252; margin-top: auto; margin-bottom: auto; margin-left: 4px;">Fel pinkod!</p>
      </div>
    </div>
  </div>
</template>
<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';

export default {
  props: ['qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      pinCode: "",
      isPinCodeCorrect: false,
      isPinError: false,
      location: undefined,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      "ismobileauthorized",
    ])
  },
  watch: {
    pinCode(newPinCode) {
      if (newPinCode != "") {
        var pinArray = newPinCode.split("")
        if (pinArray.length === 4) {
          this.checkPinCode(newPinCode);
        }
      }
    }
  },
  created() {
    if (this.qrstring != undefined) {
      HTTP.get(`/mobil/get/location/${this.qrstring}`).then(response => {
        if (!response.data.usePinCode) {
          this.$router.push({ name: "MobileSelectLegacy", params: { qrstring: this.qrstring } })
        }
        this.location = response.data
      })
    }
    if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
      HTTP.get(`/mobil/get/location/${this.workspaceid}/${this.qrworkspaceid}`).then(response => {
        if (!response.data.usePinCode) {
          this.$router.push({ name: "MobileSelect", params: { workspaceid: this.workspaceid, qrworkspaceid: this.qrworkspaceid } })
        }
        this.location = response.data
      })
    }
  },
  methods: {
    checkPinCode(pinCodeToCheck) {
      this.loading = true
      HTTP.post("/mobil/" + this.location.id + "/login", { "pincode": pinCodeToCheck }).then(() => {
        this.isPinCodeCorrect = true;
        this.loading = false
        this.$store.dispatch("saveMobileAuth", { ismobileauthorized: true })
        if (this.qrstring != undefined) {
          this.$router.push({ name: "MobileSelectLegacy", params: { qrstring: this.qrstring } })
        }
        if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
          this.$router.push({ name: "MobileSelect", params: { workspaceid: this.workspaceid, qrworkspaceid: this.qrworkspaceid } })
        }
      }).catch(() => {
        this.loading = false
        this.isPinError = true;
        this.pinCode = ""
        this.$refs.pininput.focus()
      })
    },
    checkDigit(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    }
  }
}
</script>
