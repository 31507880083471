<template>
  <v-card id="leafletmap">
    <l-map ref="map" :crs="crs" :maxZoom="maxZoom" :minZoom="minZoom" :center="center" :options="options"
      @click="addMarker">

      <l-image-overlay :url="url" :bounds="bounds">
      </l-image-overlay>

      <l-control position="topleft">
        <button-fit-bounds @fit-image-on-map="fitImageOnMap" />
      </l-control>

      <l-marker v-for="(marker, index) in currentMarker" :ref="marker.qrString" :key="index" :id="index"
        :lat-lng="marker" :icon="mobileEdit ? mapPinMobile2 : mapPin" :draggable="qrId !== -1"
        @click="removeMarker(marker.qrId);" @moveend="updateMarker($event, marker.qrId)">
        <l-tooltip v-if="qrs.length > 0" :options="{ direction: 'top', offset: [0, -50] }">
          {{ marker.qrString }} {{ !!marker.qrDescription ? '- ' + marker.qrDescription : '' }} {{ !!marker.qrPosition ?
            '- ' + marker.qrPosition : '' }}
        </l-tooltip>
      </l-marker>

    </l-map>
  </v-card>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { LMap, LImageOverlay, LMarker, LTooltip, LControl } from 'vue2-leaflet'
import _ from 'lodash'

import ButtonFitBounds from './ButtonFitBounds.vue';

import mapPinStandard from '../assets/MapMarkers/kartnal_75px.png'
import mapPinStandardShadow from '../assets/MapMarkers/kartskugg_75px.png'

import mapPinMobile from '../assets/MapMarkers/kartnal_mobile.png'
import mapPinMobileShadow from '../assets/MapMarkers/kartskugg_mobile.png'

import mapPinMobile2 from '../assets/MapMarkers/kartnal_mobile2.png'
import mapPinMobileShadow2 from '../assets/MapMarkers/kartskugg_mobile2.png'

export default {
  name: 'mapComponent',
  props: {
    qrId: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    latLng: {
      type: String,
      default: ''
    },
    mobileEdit: {
      type: Boolean,
      default: false
    },
    qrs: {
      type: Array,
      default: () => []
    },
    clearAllMarkers: {
      type: Boolean
    },
    showToolTipOnExternalHover: {
      type: String
    },
  },
  components: {
    LMap,
    LImageOverlay,
    LMarker,
    LTooltip,
    LControl,
    ButtonFitBounds
  },
  data() {
    return {
      map: null,

      maxZoom: 4,
      minZoom: -4,
      crs: L.CRS.Simple,
      options: { attributionControl: false, zoomSnap: 0.001, zoomDelta: 0.2, wheelPxPerZoomLevel: 50, wheelDebounceTime: 40 },

      bounds: [[0, 0], [0, 0]],
      center: [0, 0],
      url: this.pictureUrl(this.name),

      mapPin: L.icon(
        {
          iconUrl: mapPinStandard,
          shadowUrl: mapPinStandardShadow,

          iconSize: [36, 49], // size of the icon
          shadowSize: [75, 59], // size of the shadow
          iconAnchor: [17, 49], // point of the icon which will correspond to marker's location
          shadowAnchor: [18, 59],  // the same for the shadow
          popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        }
      ),
      mapPinMobile: L.icon(
        {
          iconUrl: mapPinMobile,
          shadowUrl: mapPinMobileShadow,

          iconSize: [76, 89], // size of the icon
          shadowSize: [115, 99], // size of the shadow
          iconAnchor: [38, 68], // point of the icon which will correspond to marker's location
          shadowAnchor: [38, 79],  // the same for the shadow
          popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        }
      ),
      mapPinMobile2: L.icon(
        {
          iconUrl: mapPinMobile2,
          shadowUrl: mapPinMobileShadow2,

          iconSize: [136, 149], // size of the icon
          shadowSize: [175, 159], // size of the shadow
          iconAnchor: [67, 99], // point of the icon which will correspond to marker's location
          shadowAnchor: [68, 109],  // the same for the shadow
          popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        }
      ),

      copyOfQrs: [],

      currentMarker: [],
      newLatLngValue: null,
      externalToolTipHover: '',
    }
  },
  computed: {
  },
  created() {
    this.getImageInfo(this.name)
    if (this.latLng) {
      this.currentMarker.push(this.returnMarker(this.latLng, { qrId: this.qrId }))
    }
    else if (this.qrs.length > 0) {
      this.copyOfQrs = _.cloneDeep(this.qrs)

      this.qrs.forEach(qr => {
        if (qr.latLng !== null) this.currentMarker.push(this.returnMarker(qr.latLng, { qrId: qr.qrId, qrString: qr.qrString, qrDescription: qr.qrDescription, qrPosition: qr.qrPosition }))
      })
    }
  },
  mounted() {
    this.map = this.$refs.map.mapObject
    setTimeout(() => {
      this.fitImageOnMap()
    }, 400)
  },
  watch: {
    name(val) {
      if (!!val === false) return
      this.getImageInfo(val)
      this.url = this.pictureUrl(val)
      setTimeout(() => {
        this.fitImageOnMap()
      }, 50)
    },
    qrs: {
      deep: true,
      handler(newQrs) {
        for (let i = 0; i < newQrs.length; i++) {
          if (this.copyOfQrs[i].latLng === null && newQrs[i].latLng !== null) {
            this.currentMarker.push(this.returnMarker(newQrs[i].latLng, { qrId: newQrs[i].qrId, qrString: newQrs[i].qrString, qrDescription: newQrs[i].qrDescription, qrPosition: newQrs[i].qrPosition }))
          }
        }
        this.copyOfQrs = _.cloneDeep(newQrs)
      }
    },
    latLng(val) {
      if (!val && this.currentMarker.length > 0) {
        this.currentMarker = []
      }
    },
    clearAllMarkers() {
      this.clearAllMarkersFromParent()
    },
    showToolTipOnExternalHover(val) {

      if (val) {
        const marker = this.$refs[val] !== undefined ? this.$refs[val][0].mapObject : null
        if (marker !== null) marker.openTooltip()

        this.externalToolTipHover = val
      }
      if (!val) {
        const marker = this.$refs[this.externalToolTipHover] !== undefined ? this.$refs[this.externalToolTipHover][0].mapObject : null
        if (marker !== null) marker.closeTooltip()
      }
    }
  },
  methods: {
    getImageInfo(imgName) {
      const self = this

      const img = new Image()
      img.src = this.pictureUrl(imgName)

      img.onload = function () {
        self.bounds = [[0, 0], [this.height, this.width]]
        self.center = [this.height / 2, this.width / 2]
      }
    },
    fitImageOnMap() {
      this.map.invalidateSize(true)
      this.map.fitBounds(this.bounds)
    },
    returnMarker(latLng, qrObj) {
      if (latLng) {
        const newLatLng = latLng.split(',')
        const newMarker = new L.LatLng(newLatLng[0], newLatLng[1])
        newMarker.qrId = qrObj.qrId
        newMarker.qrString = qrObj.qrString
        newMarker.qrDescription = qrObj.qrDescription
        newMarker.qrPosition = qrObj.qrPosition

        return newMarker
      } else {
        return []
      }
    },
    addMarker(event) {
      if (this.qrId === -1 || this.qrId === -2) return
      if (this.currentMarker.length > 0) return
      if (this.qrs.length > 0) return

      const newMarker = new L.LatLng(event.latlng.lat, event.latlng.lng)
      newMarker.qrId = this.qrId

      this.currentMarker.push(newMarker)
      this.newLatLngValue = `${event.latlng.lat},${event.latlng.lng}`
      this.$emit('updateLocation', this.newLatLngValue)
    },
    updateMarker(event, qrId) {
      if (this.qrId === -1) return

      const index = this.currentMarker.findIndex(x => x.qrId === qrId)
      this.currentMarker[index].lat = event.target._latlng.lat
      this.currentMarker[index].lng = event.target._latlng.lng

      this.newLatLngValue = `${event.target._latlng.lat},${event.target._latlng.lng}`
      this.$emit('updateLocation', this.newLatLngValue, qrId)
    },
    removeMarker(qrId) {
      if (this.qrId === -1) return
      const index = this.currentMarker.findIndex(x => x.qrId === qrId)

      this.currentMarker.splice(index, 1)
      this.newLatLngValue = null
      this.$emit('updateLocation', this.newLatLngValue, qrId)
    },
    clearAllMarkersFromParent() {
      this.currentMarker = []
    },
    pictureUrl(picturename) {
      // eslint-disable-next-line
      return process.env.VUE_APP_APIURL + '/shared/qrBilder/' + picturename
    },
  }
}
</script>

<style scoped>
#leafletmap {
  height: 100%;
  width: 100%;
  background: var(--admin-gradient) !important;
  outline: 0;
}
</style>