<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :search="search" ref="inventorytypestable" disable-sort :headers="headers"
          :items="tableItems" :items-per-page="10" :footer-props="footerProps"
          class="elevation-1 inventory-types-table">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">System Inventory Type</h2>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" color="white" @click="triggerCreate()">
                  <v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon> New
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.isActive="{ item }">
            <div>
              <v-simple-checkbox style="width: fit-content;" v-model="item.isActive"
                @click="setInventoryTypeActive(item)"></v-simple-checkbox>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="triggerUpdate(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium class="pa-2" @click="triggerDelete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="800px" class="dialogbox">
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.systemInventoryType.id === 0 ? 'New' : 'Edit' }} - System Inventory
                Type</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form onsubmit="return false;" autocomplete="off" v-model="isFormValid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field ref="description" required :rules="reqRules"
                        v-model="systemInventoryType.description" label="Name"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false; systemInventoryType = {}"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="triggerSave()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tabeItemsChecked: false,
      dialog: false,
      enableEscClear: true,
      footerProps: { 'items-per-page-options': [10, 100, -1] },
      search: '',
      headers: [
        { text: 'Inventory Type', value: 'description', width: 400 },
        { text: 'Activated', value: 'isActive' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 },

      ],
      systemInventoryType: {},
      tableItems: [],
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'issuperadministrator',
      'workspaceid'
    ]),

  },
  watch: {
    dialog(val) {
      if (val) {
        this.enableEscClear = false
        document.addEventListener('keydown', this.submitOnEnter)
      }
      if (!val) {
        document.removeEventListener('keydown', this.submitOnEnter)
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
  },
  created() {
    this.getitems();
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    getitems() {
      HTTP.get('/inventorytypes/get/system').then(response => {
        this.tableItems = response.data
      }).catch((e) => {

        if (e.response.status === 401) {
          this.$router.push('/issues')
          this.$store.dispatch('showError', "Not authorized")
        } else {
          this.$store.dispatch('showError', "Not found")
        }
      })
    },
    triggerSaveDialog() {
      this.dialog = true
      setTimeout(() => {
        this.$refs.description.focus()
      }, 200)

    },
    setInventoryTypeActive(item) {
      this.systemInventoryType = item
      HTTP.post('/inventorytypes/save/system/active', { id: this.systemInventoryType.id, isActive: this.systemInventoryType.isActive }).then((response) => {
        this.tableItems.find(item => item.id === this.systemInventoryType.id).isActive = response.data
      }).catch(e => {
        if (e.response.status === 400) {
          this.$store.dispatch('showError', 'Oops, something went wrong')
        } else {
          this.$store.dispatch('showError', 'Not found')
        }
      })
    },
    triggerCreate() {
      this.systemInventoryType = { description: "", id: 0, isActive: false }
      this.triggerSaveDialog()
    },
    triggerUpdate(dit) {
      this.systemInventoryType = dit
      this.triggerSaveDialog()
    },
    triggerDelete(dit) {
      this.systemInventoryType = dit
      this.$root.$confirm.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          this.deleteInventoryType()
        }
      })
    },
    triggerSave() {
      if (this.systemInventoryType.id === 0) {
        this.createInventoryType()
      } else {
        this.updateInventoryType()
      }
    },
    createInventoryType() {
      HTTP.post('/inventorytypes/save/system', this.systemInventoryType).then((response) => {
        this.tableItems.push(response.data)
        this.dialog = false

      }).catch(e => {
        if (e.response.status === 400) {
          this.$store.dispatch('showError', 'Oops, something went wrong')
        } else {
          this.$store.dispatch('showError', 'Not found')
        }
      })
    },
    deleteInventoryType() {
      HTTP.post('/inventorytypes/delete/system', { id: this.systemInventoryType.id }).then(() => {
        this.tableItems.splice(this.tableItems.indexOf(this.systemInventoryType), 1)
      }).catch(e => {
        if (e.response.status === 400) {
          this.$store.dispatch('showError', 'Oops, something went wrong')
        } else {
          this.$store.dispatch('showError', 'Not found')
        }
      })
    },
    updateInventoryType() {
      HTTP.post('/inventorytypes/save/system', this.systemInventoryType).then((response) => {
        var tableIndex = this.tableItems.findIndex(item => item.id === this.systemInventoryType.id)
        this.tableItems[tableIndex].description = response.data.description;
        this.dialog = false
      }).catch(e => {
        if (e.response.status === 400) {
          this.$store.dispatch('showError', 'Oops, something went wrong')
        } else {
          this.$store.dispatch('showError', 'Not found')
        }
      })
    },
    submitOnEnter(e) {
      if (e.keyCode == 13 && this.$refs.description.$refs.input == document.activeElement) {
        this.triggerSave()
      }
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>
