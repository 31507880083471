<template>
  <v-btn @click="exportTableItems()" color="white">
    <v-icon>mdi-download</v-icon>
    <span class="ml-1">{{ exportMessage }}</span>
  </v-btn>
</template>

<script>
import exportExcel from '../helpers/exportExcel';
import { mapGetters } from 'vuex';

export default {
  props: ["tableItems", "tableType", "exportMessage"],
  data() {
    return {
      exportArray: []
    }
  },
  computed: {
    ...mapGetters([
      "islegacy",
    ]),
  },
  methods: {
    exportTableItems() {
      try {
        this.createExportObjectArray()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      } finally {
        exportExcel(this.exportArray, this.tableType)
      }
    },
    createExportObjectArray() {
      this.exportArray = []
      this.exportObject = {}
      if (this.tableType == "qr_codes") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["QRID"] = this.islegacy ? tableRow.qrString : tableRow.qrWorkspaceId
          this.exportObject["Category"] = tableRow.qrCategoryDescription
          this.exportObject["Description"] = tableRow.description
          this.exportObject["Location"] = tableRow.location
          this.exportObject["Url"] = tableRow.url
          this.exportObject["QR url"] = tableRow.qrUrl

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else if (this.tableType == "inventory") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["Description"] = tableRow.description
          this.exportObject["Inventory type"] = tableRow.inventoryType.description
          this.exportObject["Active issues warning"] = tableRow.isIssueWarningActive
          this.exportObject["Selectable on QR codes"] = tableRow.isActive

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else if (this.tableType == "users") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["First name"] = tableRow.firstname
          this.exportObject["Last name"] = tableRow.lastname
          this.exportObject["e-mail"] = tableRow.email
          this.exportObject["Administrator"] = tableRow.isAdministrator

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else if (this.tableType == "issues") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["Type"] = tableRow.inventoryType
          this.exportObject["Header"] = tableRow.header
          this.exportObject["Responsible"] = tableRow.name
          this.exportObject["Created"] = tableRow.created
          this.exportObject["Status"] = tableRow.status == 0 ? "waiting" : tableRow.status == 1 ? "ongoing" : tableRow.status == 2 ? "closed" : "unknown"

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else {
        throw new Error("Not a valid table for exporting")
      }
    }
  }
}
</script>
