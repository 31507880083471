<template>
  <div class="appbackground d-flex" style="flex-direction: column; align-items: center;">
    <div style="margin-top: 12px;">
      <img src="./../assets/service-me-logo.png" style="width: 150px;">
    </div>
    <div style="display: flex; flex-direction: column; height: 100%; margin-top: 10%; align-items: center;">
      <div v-if="this.logoImage != null && this.logoImage != 'default-logo'"
        style="display:flex;justify-content:center; align-items: center;height: 100px; width: 270px">
        <img :src="`${backendurl}/shared/logo-images/${this.logoImage}`" style="max-height: 100px; max-width: 270px;"
          height="auto">
      </div>
      <div v-if="this.logoImage == 'default-logo'"
        style="display:flex;justify-content:center; align-items: end;height: 100px; width: 270px">
        <img src="./../assets/service-me-logo.png" style="max-height: 100px; max-width: 270px;" height="auto">
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';

export default {
  props: ['qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      contactRequest: 'Contact request',
      feedback: 'Feedback',
      inventorytypes: [],
      isFormsActive: {},
      felmeddelande: null,
      location: null,
      organization: null,
      logoImage: null,
      // eslint-disable-next-line no-undef
      backendurl: process.env.VUE_APP_APIURL,
      qrName: null,
      workspaceId: null,
      qrWorkspaceId: null,
    }
  },
  computed: {
    ...mapGetters([
      "ismobileauthorized",
      "isusepincodeformobileauth"
    ])
  },
  created() {
    this.$store.dispatch('saveLegacyMobileParams', this.qrstring)
    this.$store.dispatch('saveMobileParams', [this.workspaceid, this.qrworkspaceid])
    if (this.qrstring != undefined) {
      HTTP.get(`/mobil/get/logo/${this.qrstring}`).then((response) => {
        this.logoImage = response.data
      }).catch(() => {
        this.logoImage = "default-logo"
      })
    } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
      HTTP.get(`/mobil/get/logo/${this.workspaceid}/${this.qrworkspaceid}`).then((response) => {
        this.logoImage = response.data
      }).catch(() => {
        this.logoImage = "default-logo"
      })
    }
    this.$store.dispatch("deleteMobileAuth")
    HTTP.get(`/mobil/checkifqrexists/${this.qrstring}/${this.workspaceid}/${this.qrworkspaceid}`).then(response => {
      if (!response.data.isFound) {
        const wId = this.qrstring !== undefined ? response.data.legacyWorkspaceId : parseInt(this.workspaceid);
        this.$router.push({ name: 'EditMobile', params: { qrstring: this.qrstring, workspaceid: wId, qrworkspaceid: this.qrworkspaceid, qrname: this.qrName } }).catch(() => { });
      }
      this.$store.dispatch("doesOrganizationUseForms", response.data.useForms)
      if (!response.data.useForms && this.$route.fullPath.split("/")[3] === "form") {
        if (this.qrstring != undefined) {
          this.$router.push({ name: 'MobileSelectLegacy', params: { qrstring: this.qrstring } })
        } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
          this.$router.push({ name: 'MobileSelect', params: { workspaceid: this.workspaceid, qrworkspaceid: this.qrworkspaceid } })
        }
      }
      if (response.data.isUsePinCode) {
        this.$store.dispatch("usePinCodeForMobileAuth")
        if (!this.ismobileauthorized && this.$route.path.split("/").pop() != "login") {
          if (this.qrstring != undefined) {
            this.$router.push({ name: "MobileLegacyLogin", params: { qrstring: this.qrstring } })
          } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
            this.$router.push({ name: "MobileLogin", params: { workspaceid: this.workspaceid, qrworkspaceid: this.qrworkspaceid } })
          }
        }
      } else {
        this.$store.dispatch("dontUsePinCodeForMobileAuth")
      }
    }).catch((err) => {
      this.felmeddelande = err.response.data
    })
  },
}
</script>

<style>
.edit-qr {
  display: inline-block;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 120px;
  cursor: pointer;
  color: lightgray;
}

.edit-qr:hover {
  color: lightskyblue;
}

.btn-report-container {
  position: relative;
}

.btn-wrap-txt {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  width: 180px;
  max-height: 60px;
  margin: 0;
}

.active-report-icon {
  position: absolute;
  z-index: 50;
  right: -8px;
  top: -5px;
  height: 35px;
}
</style>
