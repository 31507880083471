<template>
  <div class="appbackground">
    <v-progress-linear :active="loading" indeterminate color="blue" style="margin-top: 12px;"></v-progress-linear>
    <div class="infotext text-center mb-5" v-html="getInfoText"></div>


    <v-form ref="contactform" v-model="isFormValid">
      <v-row justify="center" class="mt-1">
        <v-text-field v-model="senderName" class="messagearea smalltext" type="text" outlined
          label="Name"></v-text-field>
      </v-row>

      <v-row justify="center" class="mt-1">
        <v-col align="center">
          <v-text-field validate-on-blur required :rules="emailRules" v-model="senderEmail"
            class="messagearea smalltext" type="email" outlined label="* Email"></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-1">
        <v-text-field v-model="senderPhone" class="messagearea smalltext" type="text" outlined
          label="Phone"></v-text-field>
      </v-row>

      <v-row justify="center" class="mt-4">
        <v-col align="center">
          <v-textarea required :rules="requiredRules" v-model="senderMessage" class="messagearea" outlined
            label="* Message"></v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center">
      <v-btn class="smallbutton" @mousedown="$router.go(-1)" :loading="sending" :disabled="sending">Back</v-btn>
      <v-btn class="smallbutton ml-3" @mousedown="save()" :loading="sending" :disabled="!isFormValid">Send
        <template v-slot:loader>
          <span>Sending...</span>
        </template>
      </v-btn>
    </v-row>


  </div>
</template>


<script>

import { HTTP } from '@/plugins/backendAPI'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export default {
  props: ['formtype', 'workspaceid', 'qrworkspaceid', 'qrname'],
  data() {
    return {
      loading: false,
      sending: false,

      senderName: null,
      senderEmail: null,
      senderPhone: null,
      senderMessage: null,

      requiredRules: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'Field is required',
        v => !!emailRegex.test(v) || 'Email is in incorrect format',
      ],
      isFormValid: false,
    }
  },
  methods: {
    save() {
      HTTP.post('/mobil/sendform', {
        workspaceid: this.workspaceid,
        qrworkspaceid: this.qrworkspaceid,
        formType: this.getFormType,
        name: this.senderName,
        email: this.senderEmail,
        phone: this.senderPhone,
        message: this.senderMessage,
      }).then(() => {
        this.loading = false
        if (isNaN(this.$route.fullPath.split("/")[3])) {
          this.$router.push({ name: 'MobileLegacyRequestSuccess' });
        } else {
          this.$router.push({ name: 'MobileRequestSuccess' });
        }
      }).catch(e => {
        this.loading = false
        this.$store.dispatch('showError', e.response.data)
      })
    },
  },
  computed: {
    getFormType() {
      if (this.formtype === 'contactrequest') {
        return 'Contact request'
      } else {
        return 'Feedback'
      }
    },
    getInfoText() {
      if (this.formtype === 'contactrequest') {
        return 'Fill out below form and we will<br/>contact you as soon as possible'
      } else {
        return 'Fill out below form and we will<br/>review your feedback soon as possible'
      }
    }
  }
}
</script>


<style></style>
