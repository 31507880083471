<template>
  <div
    style="display: grid; grid-template-columns: 25% 75%; grid-gap: 12px; margin: auto; margin-top: 32px; max-width: 75%; margin-bottom: 30vh;">
    <v-card style=" z-index: 1;">
      <div style=" display: flex; border-bottom: thin solid rgba(0,0,0,.12);">
        <div
          style="display: flex; flex-wrap: wrap; justify-content: space-between; padding: 24px 24px; align-items: center;width: 100%; min-height: fit-content;">
          <div>
            <h2 style="display:flex; width: 100%; height: 100%; align-items: center; font-size: 24px;">
              Inventory Types
            </h2>
          </div>
          <div>
            <v-btn color="white" @click="newInventoryType(); isInventoryTypeInputFieldUpdate = ''">
              <v-icon medium class="py-2 mr-2">
                mdi-plus
              </v-icon>
              New
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="selectedInventoryType === ''" class="hover-button"
        @click="selectedInventoryType = ''; tableInventories = allInventories"
        style="display: flex; background-color: #eeeeee; padding-left: 40px; justify-content: center; align-items: center; width: 100; border-bottom: thin solid rgba(0,0,0,.12); min-height: 50px; cursor:pointer;">
        <h2 style="display:flex; width: 100%; height: 100%; align-items: center; font-size: .875rem;">
          All Inventories
        </h2>
      </div>
      <div v-else class="hover-button" @click="selectedInventoryType = ''; tableInventories = allInventories"
        style="display: flex; justify-content: center;padding-left: 40px; align-items: center; width: 100; border-bottom: thin solid rgba(0,0,0,.12); min-height: 50px; cursor:pointer;">
        <h2 style="display:flex; width: 100%; height: 100%; align-items: center; font-size: .875rem;">
          All Inventories
        </h2>
      </div>
      <div id="inventory-type-table" ref="inventorytypestable" style="width: 100%; height: fit-content">
        <tbody style="display:inline-block;  width: 100%;">
          <template v-for="item in tableInventoryTypes">
            <div v-if="item != selectedInventoryType" :key="item.index" id="table-row" style=" width: 100%;">
              <tr @click="searchInventoriesByInventoryTypes(item); isInventoryTypeInputFieldUpdate = ''"
                style="display:flex;align-items: center; width: 100%;border-bottom: thin solid rgba(0,0,0,.12); cursor:pointer">
                <v-icon small class=" py-2" style=" min-width: 40px;">
                  mdi-arrow-up-down
                </v-icon>
                <td style="display:flex;font-size: .875rem; width: 100%; height: 100%; align-items: center;">
                  {{ item.description }}
                </td>
                <td style="display:flex; width: 100%;font-size: .875rem; height: 100%; align-items: center;">
                  {{ item.fullname }}
                </td>
              </tr>
            </div>
            <div v-else :key="item.index" id="table-row" style="background-color: #eeeeee; width: 100%;">
              <tr @click="searchInventoriesByInventoryTypes(item); isInventoryTypeInputFieldUpdate = ''"
                style="display:flex;align-items: center; width: 100%;border-bottom: thin solid rgba(0,0,0,.12); cursor:pointer">
                <v-icon small class=" py-2" style=" min-width: 40px;">
                  mdi-arrow-up-down
                </v-icon>
                <td style="display:flex;font-size: .875rem; width: 100%; height: 100%; align-items: center;">
                  {{ item.description }}
                </td>
                <td style="display:flex;font-size: .875rem; width: 100%; height: 100%; align-items: center;">
                  {{ item.fullname }}
                </td>
              </tr>
            </div>
          </template>
        </tbody>
      </div>
    </v-card>
    <template v-if="selectedInventoryType === ''">
      <v-card style="">
        <div style="display: flex; padding: 24px 28px 24px 28px; width: 100%; ">
          <h2 class="mr-auto">All Inventories</h2>
        </div>
        <v-card-text style="padding: 0px 28px 0px 28px;">
          <v-row class="d-flex align-center" style="margin: 0px;">
            <v-col class="d-flex" style="padding-top: 0px;" cols=6>
              <v-text-field v-model="search" label="Search" style="margin-left: 0px;"></v-text-field>
              <v-icon v-if="search.length > 0" style="margin-left: -30px; margin-bottom: 5px"
                @click="search = ''">mdi-close</v-icon>
            </v-col>
            <v-col style="display: flex; flex-wrap: wrap;" cols="6">
              <ExportButton v-if="isadministrator || issuperadministrator" :table-items="searchedInventories"
                :table-type="'inventory'" :export-message="'Export List'"></ExportButton>
            </v-col>
          </v-row>
        </v-card-text>
        <v-data-table dense :search="search" :headers="allInventoriesHeader" :items="tableInventories"
          :sort-by.sync="sortOptions.sortBy" :sort-desc.sync="sortOptions.sortDesc" hide-default-footer
          :options.sync="sortHandler" :items-per-page="20" :footer-props="footerProps" ref="inventory_data_table"
          @current-items="updateSearchedInventories" disable-pagination
          style="margin: 0px 20px 24px 20px; box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12) !important;">
          <template v-slot:item.action="{ item }">
            <v-icon medium class="mr-2 pa-2" @click="editInventory(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteInventory(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-col class="switch-col">
              <v-switch @click="switchActive(item)" v-model="item.isActive" inset class="switch-btn"></v-switch>
            </v-col>
          </template>
          <template v-slot:item.isIssueWarningActive="{ item }">
            <v-col class="switch-col">
              <v-switch @click="switchActive(item)" v-model="item.isIssueWarningActive" inset
                class="switch-btn"></v-switch>
            </v-col>
          </template>
        </v-data-table>
      </v-card>
    </template>
    <div v-if="selectedInventoryType !== ''" style="display: flex; flex-direction: column; grid-gap: 12px;">
      <v-card style="">
        <v-fade-transition>
          <v-alert v-if="isInventoryTypeInputFieldUpdate == 'Description'" type="success"
            style="padding: 4px 4px 4px 12px;position: absolute; width: 100%; z-index: 1;">{{
              isInventoryTypeInputFieldUpdate }} updated successfully</v-alert>
        </v-fade-transition>
        <v-fade-transition>
          <v-alert v-if="isInventoryTypeInputFieldUpdate == 'Responsible User'" type="success"
            style="padding: 4px 4px 4px 12px;position: absolute; width: 100%; z-index: 1;">{{
              isInventoryTypeInputFieldUpdate }} updated successfully</v-alert>
        </v-fade-transition>
        <v-card-title v-if="!doesOrgHaveInventoryTypes" style="display:flex; padding: 24px 28px 24px 28px;">
          <h3 style="margin-right: auto;"> No Inventory Type Selected </h3>
          <Tooltip :message="tooltipMessage"></Tooltip>
        </v-card-title>
        <v-card-title v-else style="display:flex; padding: 24px 28px 24px 28px;">
          <h3 style=""> {{ this.selectedInventoryType.description }} </h3>
          <v-btn color="white" :icon="true" @click="deleteInventoryType(selectedInventoryType)"
            style="width: fit-content; height: 100%; margin-right: auto; margin-left: 12px">
            <v-icon color="grey darken-1" style="padding-right: 1px; margin: 2px">
              mdi-delete
            </v-icon>
          </v-btn>
          <Tooltip :message="tooltipMessage"></Tooltip>
        </v-card-title>
        <v-card-text v-if="doesOrgHaveInventoryTypes" style="padding-left: 28px; padding-right: 28px;">
          <v-container class="pa-0">
            <v-form v-model="isFormValid" ref="form">
              <v-row>
                <v-col style="position: relative; padding-top: 0px" cols="6">
                  <div class="d-flex text-field-inputs" ref="text_field_inputs" style="align-items: center;">
                    <v-text-field :disabled="!isInventoryTypeDescriptionEditable"
                      @focus="focusOnDescriptionInputField()" ref="inventory_type_description" required
                      :rules="reqRules" v-model="inventoryTypeDescriptionTextFieldOutput" label="Description">
                      <template v-slot:append-outer>
                        <v-btn v-if="!isInventoryTypeDescriptionEditable" :icon="true" color="white"
                          class="icon_buttons" style="margin: 0px 0px 6px 0px; width: fit-content; height: fit-content"
                          @click="toggleDescriptionInputFieldEditable()">
                          <v-icon color="grey darken-1">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-row v-if="isInventoryTypeDescriptionEditable" justify="end"
                          style="display: flex; width: 100%; bottom: -18px; left: 0px; position: absolute; transform: translate(-2px, 0px);">
                          <v-btn class="icon_buttons floating_buttons" :icon="true" color="white"
                            ref="text_field_confirm"
                            style="margin: 6px 8px 6px 8px ; width: fit-content; height: fit-content">
                            <v-icon color="grey darken-1" style="padding-right: 1px; margin: 2px">
                              mdi-check
                            </v-icon>
                          </v-btn>
                          <v-btn class="icon_buttons floating_buttons" :icon="true" color="white"
                            ref="text_field_cancel"
                            style="margin: 6px 8px 6px 8px; width: fit-content; height: fit-content">
                            <v-icon color="grey darken-1" style="padding-right: 1px; margin: 2px">
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-row>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="d-flex"
                    style="width: 100%; justify-content: end; position: absolute; transform: translate(-28px, 0px);">
                  </div>
                </v-col>
                <v-col style="padding-top: 0px" cols="6">
                  <v-select required :rules="reqRules" item-value="id" return-object :item-text="getItemTextName"
                    v-model="userSelected" ref="inventory_type_responsible_user" :nudge-bottom="10" :items="userItems"
                    label="Responsible User"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card style="flex-grow: 1;">
        <v-card-text style="padding: 0px 28px 0px 28px;">
          <v-row class="d-flex align-center">
            <v-col class="d-flex" cols=6>
              <v-text-field v-model="search" label="Search" style="margin-left: 0px;"></v-text-field>
              <v-icon v-if="search.length > 0" style="margin-left: -30px; margin-bottom: 5px"
                @click="search = ''">mdi-close</v-icon>
            </v-col>
            <v-col style="display: flex; flex-wrap: wrap;" cols="6">
              <v-btn color="white" style="margin-right: 8px;" @click="newInventory()">
                <v-icon medium class="py-2 mr-2">mdi-plus</v-icon>
                New {{ selectedInventoryType.description }}
              </v-btn>
              <ExportButton v-if="isadministrator || issuperadministrator" :table-items="searchedInventories"
                :table-type="'inventory'" :export-message="'Export List'"></ExportButton>
            </v-col>
          </v-row>
        </v-card-text>
        <v-data-table dense :search="search" :headers="headers" :items="tableInventories"
          :sort-by.sync="sortOptions.sortBy" :sort-desc.sync="sortOptions.sortDesc" hide-default-footer
          :options.sync="sortHandler" :items-per-page="20" :footer-props="footerProps" ref="inventory_data_table"
          @current-items="updateSearchedInventories" disable-pagination
          style="margin: 0px 20px 24px 20px; box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12) !important;">
          <template v-slot:item.action="{ item }">
            <v-icon medium class="mr-2 pa-2" @click="editInventory(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteInventory(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-col class="switch-col">
              <v-switch @click="switchActive(item)" v-model="item.isActive" inset class="switch-btn"></v-switch>
            </v-col>
          </template>
          <template v-slot:item.isIssueWarningActive="{ item }">
            <v-col class="switch-col">
              <v-switch @click="switchActive(item)" v-model="item.isIssueWarningActive" inset
                class="switch-btn"></v-switch>
            </v-col>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog v-model="dialogInventoryType" max-width="800px" class="dialogbox">
      <v-card>
        <v-card-title>
          <span class="headline">New - Inventory Type</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValid" ref="form">
              <v-row>
                <v-col cols="6">
                  <v-combobox ref="new_inventory_type_description" required :rules="reqRules"
                    v-model="newInventoryTypeDescription" label="Description" :items="allSystemInventoryTypes"
                    @change="onAutoCompleteSelection" @keyup="customOnChangeHandler" @paste="customOnChangeHandler"
                    item-text="description"></v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete required :rules="reqRules" return-object auto-select-first item-value="id"
                    :item-text="getItemTextName" v-model="newUserSelected" :items="userItems"
                    label="Responsible User"></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="dialogInventoryType = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="saveNewInventoryType()"
            :disabled="newSelectedInventoryType.description === '' || newSelectedInventoryType.description === null || newSelectedInventoryType.description === undefined || newUserSelected === '' || newUserSelected === null || newUserSelected === undefined"><v-icon
              small class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInventory" max-width="600px" class="dialogbox">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.editedInventory.id === 0 ? 'New' : 'Edit' }} - {{
            this.selectedInventoryType.description }} - Inventory</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="newInventory" v-model="isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field required :rules="reqRules" ref="description" v-model="editedInventory.description"
                    label="Description"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedInventory.instructionHeader" label="Header" placeholder="Instructions"
                    dense></v-text-field>
                </v-col>
              </v-row>
              <tiptap-vuetify v-model="editedInventory.instruction"
                placeholder="Write inventory specific instructions here..." :extensions="extensions" />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="dialogInventory = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="saveInventory()" :disabled="!isFormValid">
            <v-icon small class="py-2 mr-1">mdi-content-save</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, HardBreak, History, Link } from 'tiptap-vuetify'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/Tooltip.vue'
import ExportButton from '../../components/ExportButton.vue'
import Sortable from 'sortablejs'

export default {
  components: { TiptapVuetify, Tooltip, ExportButton, },
  data() {
    return {
      skipNextCustomOnChangeHandler: false,
      dialogInventoryType: false,
      isInventoryTypeDescriptionEditable: false,
      doesOrgHaveInventoryTypes: false,
      isLoadingInventoryTypes: false,
      dialogInventory: false,
      enableEscClear: true,
      selectedInventoryType: "",
      newInventoryTypeDescription: "",
      isInventoryTypeInputFieldUpdate: "",
      search: '',
      inventoryTypeDescriptionTextFieldOutput: "",
      editedInventory: {},
      inventoryTypeBeforeUpdate: {},
      newSelectedInventoryType: {},
      inventoryTypeSelected: null,
      userSelected: {},
      newUserSelected: {},
      tableInventoryTypes: [],
      allSystemInventoryTypes: [],
      allInventories: [],
      tableInventories: [],
      searchedInventories: [],
      userItems: [],
      inventoryTypes: [],
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { text: 'Description', value: 'description', width: '50%' },
        { text: 'Active issues warning', value: 'isIssueWarningActive', sortable: false },
        { text: 'Selectable on QR codes', value: 'isActive', sortable: false },
        { text: 'Edit', value: 'action', sortable: false }
      ],
      allInventoriesHeader: [
        { text: 'Description', value: 'description', width: '40%' },
        { text: 'Inventory Type', value: 'inventoryType.description', width: '15%' },
        { text: 'Active issues warning', value: 'isIssueWarningActive', width: '10%', sortable: false },
        { text: 'Selectable on QR codes', value: 'isActive', width: '10%', sortable: false },
        { text: 'Edit', value: 'action', width: '15%', sortable: false }
      ],
      inventoryTypeHeaders: [
        { text: 'Sort', value: 'sortorder', width: "10px" },
        { text: 'Description', value: 'description' },
      ],
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,

      extensions: [
        History,
        // Blockquote,
        [Link, {
          options: {
            target: '_blank',
          }
        }],
        Bold,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        // Code,
        Paragraph,
        HardBreak,
      ],

      instructions: ``,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipMessageInventoryType: [
        "Inventory Type",
        'Create or edit inventory types. Examples of inventory types can include a chair, monitor, or keyboard. It\'s not a specific inventory but a broader description. This is also where you choose which administrator receives reports for each inventory type.'
      ],
      tooltipMessage: [
        "Inventory",
        'The inventory page is where you add new inventory to link with a QR code. If the "Active issues warning" switch is on, a reporter will be notified if an item has already been reported after scanning the QR code. You can deactivate an inventory if it\'s temporarily removed from your site or office. Edit current inventories by clicking the edit icon (pen) or delete with the trash icon.',
      ],
    }
  },
  created() {
    this.getEverything(this.workspaceid)
    this.sortOptions = this.sort.inventorySort || this.sortOptions
    EventBus.$on('updateinventory', workspaceid => {
      this.getEverything(workspaceid)
    })
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
    this.initDragAndDroptable()
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid',
      "isadministrator",
      "issuperadministrator"
    ]),
    isInstructionEmpty() {
      return this.editedInventory.instruction === null ||
        this.editedInventory.instruction === '' ||
        this.editedInventory.instruction === '<p></p>' ||
        this.editedInventory.instruction === '<h1></h1>' ||
        this.editedInventory.instruction === '<h2></h2>' ||
        this.editedInventory.instruction === '<h3></h3>';
    },
    isInstructionHeaderEmpty() {
      if (this.editedInventory.instructionHeader === null || this.editedInventory.instructionHeader === undefined) return true
      if (this.editedInventory.instructionHeader.trim() === '') return true
      return false
    }
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.inventorySort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    dialogInventory(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    dialogInventoryType(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    userSelected(updatedValue) {
      if (updatedValue === undefined) return
      if (updatedValue.id !== this.selectedInventoryType.userid) {
        this.updateInventoryTypeResponsibleUser(updatedValue)
        this.tableInventoryTypes.find(tit => tit.id === this.selectedInventoryType.id).userid = updatedValue.id
      }
      this.$refs.inventory_type_responsible_user.blur()

    },
    isInventoryTypeInputFieldUpdate(val) {
      if (val !== "") {
        setTimeout(() => {
          this.isInventoryTypeInputFieldUpdate = ""
        }, 2000)
      }
    },
  },
  methods: {
    onChangeHandler() {
      // eslint-disable-next-line no-console
      console.log('So this is your favorite number: ' + this.newSelectedInventoryType.description);
    },
    onAutoCompleteSelection() {
      this.skipNextCustomOnChangeHandler = true
      this.newSelectedInventoryType.description = this.newInventoryTypeDescription;
      this.onChangeHandler();
    },
    customOnChangeHandler() {
      if (!this.skipNextCustomOnChangeHandler) {
        let vm = this;
        setTimeout(function () {
          if (vm.$refs.new_inventory_type_description) {
            vm.newSelectedInventoryType.description = vm.$refs.new_inventory_type_description.internalSearch;
            vm.onChangeHandler();
          }
        });
      }
      else {
        this.skipNextCustomOnChangeHandler = false
      }
    },
    toggleDescriptionInputFieldEditable() {
      if (!this.isInventoryTypeDescriptionEditable) {
        this.isInventoryTypeDescriptionEditable = true
        this.$nextTick(() => {
          this.$refs.inventory_type_description.$refs.input.focus()
        })
      }
      else {
        this.isInventoryTypeDescriptionEditable = false
      }
    },
    focusOnDescriptionInputField() {
      this.isInventoryTypeInputFieldUpdate = ""
      const textFieldInputElement = this.$refs.text_field_inputs
      textFieldInputElement.addEventListener('blur', this.onTextFieldInputBlur, { capture: true, once: true })
      const inventoryTypeDescriptionElement = this.$refs.inventory_type_description.$refs.input
      inventoryTypeDescriptionElement.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          this.updateInventoryTypeDescription()
        }
      })
    },
    onTextFieldInputBlur(event) {
      const textFieldConfirmElement = this.$refs.text_field_confirm
      if (textFieldConfirmElement != undefined) {
        if (event.relatedTarget === textFieldConfirmElement.$el) {
          event.preventDefault()
          this.updateInventoryTypeDescription()
          this.isInventoryTypeDescriptionEditable = false
        } else {
          event.preventDefault()
          this.inventoryTypeDescriptionTextFieldOutput = this.selectedInventoryType.description
          this.isInventoryTypeDescriptionEditable = false
        }
      }
      const inventoryTypeDescriptionElement = this.$refs.inventory_type_description.$refs.input
      inventoryTypeDescriptionElement.removeEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          this.updateInventoryTypeDescription()
        }
      })
    },
    updateInventoryTypeDescription() {
      if (this.selectedInventoryType.description !== this.inventoryTypeDescriptionTextFieldOutput) {
        HTTP.post(`inventorytypes/save/${this.selectedInventoryType.index}?description=${this.inventoryTypeDescriptionTextFieldOutput}`).then((response) => {
          this.isInventoryTypeInputFieldUpdate = "Description"
          this.isInventoryTypeDescriptionEditable = false
          var tableIndex = this.tableInventoryTypes.findIndex(item => item.id === this.selectedInventoryType.id)
          this.tableInventoryTypes[tableIndex].description = response.data.description;
          this.selectedInventoryType = this.tableInventoryTypes[tableIndex]
        }).catch(() => {
          this.$store.dispatch('showError', 'Description could not be saved. Try again later.')
        })
      }
      else {
        this.inventoryTypeDescriptionTextFieldOutput = this.selectedInventoryType.description
        this.isInventoryTypeDescriptionEditable = false
      }
    },
    updateInventoryTypeResponsibleUser(user) {
      HTTP.post(`inventorytypes/save/${this.selectedInventoryType.index}/user/${user.id}`).then(() => {
        this.isInventoryTypeInputFieldUpdate = "Responsible User"
        this.selectedInventoryType.fullname = user.firstname + " " + user.lastname
      }).catch(() => {
        this.$store.dispatch('showError', 'Responsible User could not be saved. Try again later.')
      })
    },
    async getEverything(workspaceid) {
      await this.getInventories(workspaceid)
      await this.getInventoryTypes(workspaceid)
    },
    searchInventoriesByInventoryTypes(inventoryType) {
      this.editInventoryType(inventoryType)
      this.tableInventories = this.allInventories.filter(ti => ti.inventoryType.index === inventoryType.index)
    },
    async getInventoryTypes(workspaceid) {
      await HTTP.get('/inventorytypes/get/a/' + workspaceid).then(response => {
        this.doesOrgHaveInventoryTypes = true
        this.tableInventoryTypes = response.data
        this.selectedInventoryType = ""
        this.tableInventories = this.allInventories

      }).catch(() => {
        this.selectedInventoryType = ""
        this.tableInventoryTypes = {}
        this.doesOrgHaveInventoryTypes = false
      })
    },
    newInventoryType() {
      if (this.workspaceid === -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created atleast one workspace and that it is selected.')
        return
      }
      HTTP.get(`/inventorytypes/get/system/w/${this.workspaceid}?include-inactive=false`).then((response) => {
        this.allSystemInventoryTypes = response.data
      })
      this.newSelectedInventoryType = { description: null, id: 0, sortorder: this.tableInventoryTypes.length, fullname: null, userid: null, inventoryTypeCategory: 1 }
      this.dialogInventoryType = true
      this.newUserSelected = null
      HTTP.get('/inventory/getusers/' + this.workspaceid).then(response => {
        this.userItems = response.data
      })
    },
    editInventoryType(InventoryType) {
      var self = this
      self.selectedInventoryType = InventoryType
      this.inventoryTypeDescriptionTextFieldOutput = InventoryType.description
      HTTP.get('/inventory/getusers/' + this.workspaceid).then(response => {
        self.userItems = response.data
        self.userSelected = self.userItems.find(i => i.id === self.selectedInventoryType.userid)
      })
    },
    deleteInventoryType(InventoryType) {
      const self = this
      this.$root.$confirm.open('Delete InventoryType', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/inventorytypes/delete', { id: InventoryType.id, inventoryTypeCategory: InventoryType.inventoryTypeCategory, workspaceid: this.workspaceid }).then(() => {
            self.tableInventoryTypes.splice(self.tableInventoryTypes.indexOf(InventoryType), 1)
            self.saveSortOrderToDb()
            this.selectedInventoryType = this.tableInventoryTypes[0]
            this.inventoryTypeDescriptionTextFieldOutput = this.selectedInventoryType.description
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    saveNewInventoryType() {
      var self = this
      if (typeof this.newSelectedInventoryType.description === "string") {
        self.newSelectedInventoryType.userid = self.newUserSelected.id;
        self.newSelectedInventoryType.fullname = self.newUserSelected.firstname + ' ' + self.newUserSelected.lastname;
        self.newSelectedInventoryType.sortorder = parseInt(self.newSelectedInventoryType.sortorder);
        self.newSelectedInventoryType.workspaceid = this.workspaceid;
        HTTP.post('/inventorytypes/save/a', self.newSelectedInventoryType).then(response => {
          self.newSelectedInventoryType = response.data;
          self.tableInventoryTypes.push(response.data);
          self.selectedInventoryType = response.data;
          self.searchInventoriesByInventoryTypes(self.selectedInventoryType)
          self.newInventoryTypeDescription = ""
          self.dialogInventoryType = false;
        }).catch(() => {
          self.$store.dispatch('showError', 'Error when saving: Try reloading the page');
          self.newInventoryTypeDescription = ""
        })
      } else {
        const newSystemInventoryTypeInstance = { id: 0, responsibleUserId: self.newUserSelected.id, sortOrder: parseInt(self.newSelectedInventoryType.sortorder), systemInventoryTypeId: self.newSelectedInventoryType.description.id, workspaceId: self.workspaceid }
        HTTP.post('/inventorytypes/create/system-instance', newSystemInventoryTypeInstance).then(response => {
          self.newSelectedInventoryType = response.data;
          self.tableInventoryTypes.push(response.data);
          self.selectedInventoryType = response.data
          self.searchInventoriesByInventoryTypes(self.selectedInventoryType)
          self.newInventoryTypeDescription = ""
          self.dialogInventoryType = false;
        }).catch(() => {
          self.$store.dispatch('showError', 'Error when saving: Try reloading the page');
          self.newInventoryTypeDescription = ""
        })
      }
    },
    getItemTextName(item) {
      return `${item.firstname} ${item.lastname}`;
    },
    initDragAndDroptable() {
      const table = document.getElementById("inventory-type-table").querySelector("tbody")
      const self = this
      new Sortable(table, {
        animation: 100,
        onStart(e) {
          e.from.children[e.oldIndex].classList.add('dragged-row')
        },
        onEnd(e) {
          const movedItem = self.tableInventoryTypes.splice(e.oldIndex, 1)[0]
          self.tableInventoryTypes.splice(e.newIndex, 0, movedItem)
          e.from.children[e.newIndex].classList.remove('dragged-row')
          self.saveSortOrderToDb()
        }
      })
    },
    saveSortOrderToDb() {
      if (this.tableInventoryTypes.length === 0) return
      this.tableInventoryTypes.forEach((item, index) => {
        item.sortorder = index
        item.workspaceid = this.workspaceid
      })
      HTTP.post(`/inventorytypes/save/a/sortorder`, this.tableInventoryTypes, { params: { "workspace-id": this.workspaceid } }).then(() => { }).catch(() => {
        this.$store.dispatch('showError', "Data table out of sync: Reload page")
      })
    },
    updateSearchedInventories() {
      this.$nextTick(() => {
        this.searchedInventories = this.$refs.inventory_data_table.$children[0].filteredItems
      });
    },
    showDialog() {
      this.dialogInventory = true
      setTimeout(() => {
        this.$refs.description.focus()
      }, 300)
    },
    async getInventories(workspaceid) {
      await HTTP.get('/inventory/get/' + workspaceid).then(response => {
        this.allInventories = response.data
      }).catch(() => {
        this.allInventories = []
        this.tableInventories = []
      })
    },
    newInventory() {
      if (this.workspaceid === -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected.')
        return
      }
      this.inventorytypeSelected = this.selectedInventoryType
      this.editedInventory = { id: 0, description: null, inventoryTypeId: 0, inventoryType: null, isActive: true, isIssueWarningActive: true }
      this.showDialog()
      if (this.$refs.newInventory) this.$refs.newInventory.reset()
    },
    editInventory(Inventory) {
      var self = this
      HTTP.get('/inventorytypes/get/a/' + self.workspaceid).then(response => {
        self.editedInventory = Object.assign({}, Inventory)
        self.inventorytypeSelected = response.data.find(i => i.id === Inventory.inventoryTypeId)
        self.showDialog()
      })
    },
    deleteInventory(Inventory) {
      this.$root.$confirm.open('Delete Inventory', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/inventory/delete', Inventory).then(() => {
            this.allInventories.splice(this.allInventories.indexOf(Inventory), 1)
            this.tableInventories = this.allInventories.filter(ti => ti.inventoryType.index === this.selectedInventoryType.index)
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    saveInventory() {
      var self = this;
      self.editedInventory.inventoryTypeId = self.inventorytypeSelected.id;
      self.editedInventory.inventoryType = self.inventorytypeSelected;
      self.editedInventory.workspaceid = this.workspaceid;
      if (this.isInstructionEmpty) self.editedInventory.instruction = null;
      if (this.isInstructionHeaderEmpty) self.editedInventory.instructionHeader = null;
      HTTP.post('/inventory/save', self.editedInventory).then(response => {
        if (self.editedInventory.id > 0) {
          var editedIndex = self.allInventories.findIndex(q => q.id === self.editedInventory.id);
          self.allInventories[editedIndex].description = self.editedInventory.description;
          self.allInventories[editedIndex].inventoryType = self.editedInventory.inventoryType;
          self.allInventories[editedIndex].instruction = self.editedInventory.instruction;
          self.allInventories[editedIndex].instructionHeader = self.editedInventory.instructionHeader;
        } else {
          self.editedInventory.id = response.data;
          self.allInventories.push(self.editedInventory);
        }
        this.tableInventories = this.allInventories.filter(ti => ti.inventoryType.index === this.selectedInventoryType.index)
        self.dialogInventory = false;
      }).catch((e) => {
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    switchActive(item) {
      HTTP.post('/inventory/save', item).then(() => { }).catch(e => {
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>

<style>
#inventory-type-table tr:hover {
  background-color: lightgrey !important;
}

.hover-button:hover {
  background-color: lightgrey !important;
}

.icon_buttons {
  border-width: 2px;
  border-color: white;
  border-style: solid;
  border-radius: 2px !important;
}

.floating_buttons {
  background-color: white !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12) !important;
}

.icon_buttons:hover {
  border-color: grey;
}
</style>
